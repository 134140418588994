import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';

import { RailsContext } from '../../lib/utils/RailsContext';

import 'react-dates/lib/css/_datepicker.css';

import FieldStyles  from 'lib/components/Field/Field.module.scss';
import './react_dates_overrides.scss';

export default class DobPicker extends React.Component {
  static contextType = RailsContext;
  constructor(props) {
    super(props);
    this.state = { focused: false };
    this.renderMonthElement = this.renderMonthElement.bind(this);
    this.hasError = this.hasError.bind(this);
  }

  renderMonthElement({ month, onMonthSelect, onYearSelect }) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <select
            style={{ height: "30px", background: "#efefef", border: "none" }}
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={label}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            style={{ height: "30px", background: "#efefef", border: "none" }}
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {Array.from(
              { length: 100 },
              (_, i) => moment().subtract(99, "years").year() + i
            ).map((label, value) => (
              <option value={label} key={label}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  hasError() {
    return this.props.error.length > 0 ? "has-error" : "";
  }

  render() {
    moment.locale(this.context.i18nLocale);
    return (
      <div
        className={[
          `form-group required dob-picker ${this.hasError()}`,
          FieldStyles.reactFormGroup,
        ].join(" ")}
      >
        <label className="control-label dob-picker required">
          {this.props.required ? <abbr title="required">*</abbr> : ""} Date of
          Birth
        </label>
        <div>
          <SingleDatePicker
            placeholder="Select date"
            required={this.props.required}
            showClearDate={true}
            showDefaultInputIcon={true}
            isOutsideRange={(day) => day.isAfter(moment())}
            numberOfMonths={1}
            initialVisibleMonth={() => moment().subtract(5, "years")}
            date={this.props.date}
            hideKeyboardShortcutsPanel={true}
            onDateChange={this.props.setDate}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            id={`dob_picker_${this.props.memberIndex}`}
            renderMonthElement={this.renderMonthElement}
          />
          <input
            type="hidden"
            name={`membership[members_attributes][${this.props.memberIndex}][dob]`}
            id={`membership_members_attributes_${this.props.memberIndex}_dob`}
            value={this.props.date || ""}
          />
          {this.props.error ? (
            <span className="help-block">{this.props.error}</span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

DobPicker.propTypes = {
  // dob: PropTypes.string,
  memberIndex: PropTypes.number.isRequired,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
};



