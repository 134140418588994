import React from "react";
import PropTypes from "prop-types";

import FieldsetStyles from "lib/components/Fieldset/Fieldset.module.scss";
import { renderCurrency } from "lib/utils/CurrencyUtils";

import styles from "./Membership.module.scss";
import { __values } from "tslib";

export default class MembershipCostBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      breakdown: [],
      fetchingTotal: false
    };
    this.parseMemberEnitities = this.parseMemberEnitities.bind(this);
    this.memberEntityCount = this.memberEntityCount.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.members.length != this.props.members.length ||
      JSON.stringify(nextState.breakdown) !=
        JSON.stringify(this.state.breakdown) ||
      JSON.stringify(nextProps.members) !=
        JSON.stringify(this.props.members)
    );
  }

  componentDidMount() {
    this.calculateTotal();
  }

  componentDidUpdate(prevProps, prevState) {
    this.calculateTotal();
  }

  calculateTotal() {
    this.setState({ fetchingTotal: true });
    $.ajax({
      url: `/api/membership_products/${this.props.product.id}/pricing_calculations/`,
      dataType: "json",
      type: "POST",
      data: JSON.stringify({
        pricing_calculation: {
          member_entities: this.parseMemberEnitities()
        }
      }),
      headers: {
        "cache-control": "no-cache",
        Accept: "application/json;version=3",
        "Content-Type": "application/json",
        "X-ClubZap-App-Id": "ClubZapWebsite"
      },
      success: data => {
        this.setState({
          total: data.total,
          discount: data.discount,
          breakdown: data.entities,
          additionalFees: data.additional_fees,
          fetchingTotal: false
        });
      },
      error: (xhr, status, err) => {
        this.setState({ fetchingTotal: false });
      }
    });
  }

  parseMemberEnitities() {
    return this.props.memberEntities.map(entity => ({
      id: entity.id,
      count: this.memberEntityCount(entity)
    }));
  }

  memberEntityCount(entity) {
    return this.props.members.filter(member => member?.entity?.id === entity.id)
      .length;
  }

  render() {
    let memberCount = this.props.members.length;
    const clubId = this.props.clubId;
    return (
      <div className="member_fields">
        <div className={FieldsetStyles.legend}>{`${
          clubId != 2323 ? "Membership " : ""
        }Cost Breakdown`}</div>
        <div className={styles.breakdownSummary}>
          <div className={styles.memberCount}>
            <h3>
              {`${clubId != 2323 ? "Members" : "Children"}`}: {memberCount}
            </h3>
          </div>
          <div className={styles.costTotal}>
            {!isNaN(this.state.discount) &&
              this.state.discount > 0 &&
              this.props.showBreakdown && (
                <div>
                  <h4>
                    Subtotal:{" "}
                    {calculatingText(
                      this.state.fetchingTotal,
                      this.state.total + this.state.discount,
                      this.props.product
                    )}
                  </h4>
                  <p>
                    (Discount:{" "}
                    {calculatingText(
                      this.state.fetchingTotal,
                      this.state.discount,
                      this.props.product
                    )}
                    )
                  </p>
                </div>
              )}
            <h3>
              Total:{" "}
              {calculatingText(
                this.state.fetchingTotal,
                this.state.total,
                this.props.product
              )}
            </h3>
          </div>
          {this.state.breakdown.length > 0 && (
            <CostBreakdown
              entities={this.state.breakdown}
              fees={this.state.additionalFees}
              showBreakdown={this.props.showBreakdown}
              product={this.props.product}
            />
          )}
        </div>
      </div>
    );
  }
}

const calculatingText = (isCalculating, value, product) =>
  isCalculating ? "Calculating" : renderCurrency({price: value, currency: product.currency});

MembershipCostBreakdown.propTypes = {
  product: PropTypes.object.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  memberEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  showBreakdown: PropTypes.bool.isRequired
};

const CostBreakdown = ({ entities, fees, showBreakdown, product }) => (
  <div className={styles.breakdown}>
    {showBreakdown &&
      fees.map((fee) => (
        <p key={fee.id.toString()}>{`${fee.name}: ${renderCurrency({
          price: fee.price,
          currency: product.currency
        })}`}</p>
      ))}
    {entities.map(
      (entity) =>
        entity.count > 0 && (
          <p key={entity.id.toString()}>
            {showBreakdown
              ? `${entity.name} (${entity.count}): ${renderCurrency({price: entity.total, currency: product.currency})}`
              : `${entity.name}: ${entity.count}`}
          </p>
        )
    )}
  </div>
);

CostBreakdown.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired
};
