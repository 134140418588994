import React from "react";
import PropTypes from "prop-types";
import styles from "../MembershipRenewal.module.scss";

const FormResponse = ({responseMessage}) => (
  <div
    className={`${styles.responseContainer} ${
      responseMessage.status === "success"
        ? styles.responseSuccess
        : styles.responseError
    }`}
  >
    {responseMessage.status === "success" ? (
      <h3>Success - Email Sent</h3>
    ) : (
      <h3>{responseMessage.title || "Error"}</h3>
    )}
    <p dangerouslySetInnerHTML={{__html: responseMessage.message}} />
  </div>
)

FormResponse.propTypes = {
  responseMessage: PropTypes.object.isRequired,
}

export default FormResponse;
