import React        from 'react';
import PropTypes    from 'prop-types';

import styles       from './Label.module.scss';
import { centered } from 'lib/components/Field/Field.module.scss';

let Label = (props) => {
  let padding = (props.padding || 20) + 'px';
  return (
    <div
      className={[styles.label, centered].join(' ')}
      style={{ padding: '0 ' + padding }}>
      <div className={styles.text}>{props.text}</div>
      {props.description ? <div className={styles.description}>{props.description}</div> : null}
    </div>
  );
};

export default Label;

// Label.PropTypes = {
//   text: PropTypes.node.describe(
//     'The main text/node of the label.'
//   ),
//   description: PropTypes.node.describe(
//     'The secondary text/node of the label.'
//   ),
//   padding: PropTypes.number.describe(
//     'Allows you to override the left-right padding of the label.'
//   )
// };
