import React from "react";
import PropTypes from "prop-types";

import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

import Field from "lib/components/Field/Field";
import Checkbox from "lib/components/Checkbox/Checkbox";
import FieldStyles from "lib/components/Field/Field.module.scss";
import FileField from "lib/components/FileField";
import Label from "lib/components/Label/Label";
import Toggle from "lib/components/Toggle/Toggle";
import DobPicker from "components/DatePickers/DobPicker";
import DatePicker from "lib/components/DatePicker";
import MultiSelect from "lib/components/MultiSelect/MultiSelect";
import MultiSelectOption from "lib/components/MultiSelect/MultiSelectOption";
import Modal from "lib/components/Modal/Modal";
import SelectField from "lib/components/SelectField";

import styles from "./Membership.module.scss";

const BaseField = ({ type, ...args }) => {
  switch (type) {
    case "dob": {
      return <DobPicker {...args} />;
    }
    case "file": {
      return <FileField {...args} />;
    }
    case "date": {
      return <DateField {...args} />;
    }
    case "option_select":
    case "select":
    case "radio_buttons":
    case "radio": {
      return <SelectField {...args} />;
    }
    case "boolean":
      let innerValue;
      if (typeof args.value === "string" && args.value.length === 0) {
        innerValue = "0";
      } else {
        innerValue = args.value;
      }
      return <AcceptBoolean {...args} />;
    case "accept":
      return <AcceptField {...args} />;
    case "checkbox": {
      return <CheckboxField {...args} />;
    }
    case "checkboxes":
    case "check_boxes":
      return (
        <CollectionCheckboxesField
          {...args}
          value={typeof args.value === "string" ? args.value.split(",") : []}
        />
      );
    case "multi_select":
      return <CollectionMultiSelectField {...args} />;
    case "email":
      return <TextField type="email" {...args} />;
    case "textarea":
      return <TextArea {...args} />;
    default: {
      return <TextField {...args} />;
    }
  }
};

export const DynamicField = ({ type, ...args }) => {
  if (Object.keys(args).includes("description")) {
    return (
      <div className={styles.fieldContainer}>
        <p dangerouslySetInnerHTML={{ __html: args.description }} />
        <BaseField type={type} {...args} />
      </div>
    );
  } else {
    return <BaseField type={type} {...args} />;
  }
};

DynamicField.propTypes = {
  type: PropTypes.string,
};

const RadioField = ({
  label,
  collection,
  value,
  isRequired,
  handleOnChange,
  className,
  error = "",
  placeHolder = "",
}) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label string">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <MultiSelect
        value={value}
        onChange={handleOnChange}
        placeHolder={placeHolder.length > 0 ? placeHolder : "Please select..."}
      >
        {collection.map((option) => (
          <MultiSelectOption key={option[0]} value={option[0]}>
            {option[1]}
          </MultiSelectOption>
        ))}
      </MultiSelect>
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

const CollectionMultiSelectField = ({
  label,
  collection,
  value,
  isRequired,
  handleOnChange,
  className,
  error = "",
  placeHolder = "",
}) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label string">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <MultiSelect
        value={value}
        onChange={(newVals) => handleOnChange(newVals)}
        placeHolder={placeHolder.length > 0 ? placeHolder : "Please select..."}
      >
        {collection.map((option) => (
          <MultiSelectOption
            key={option[0]}
            value={option[0]}
            checked={(value || []).includes(option[0])}
          >
            {option[1]}
          </MultiSelectOption>
        ))}
      </MultiSelect>
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

const CollectionCheckboxesField = ({
  label,
  collection,
  value,
  isRequired,
  handleOnChange,
  className,
  error = "",
  placeHolder = "",
}) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label string">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <MultiSelect
        value={value}
        onChange={(newVals) => handleOnChange(newVals.join(","))}
        placeHolder={placeHolder.length > 0 ? placeHolder : "Please select..."}
      >
        {collection.map((option) => (
          <MultiSelectOption
            key={option}
            value={option}
            checked={value.includes(option)}
          >
            {option}
          </MultiSelectOption>
        ))}
      </MultiSelect>
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

const CheckboxField = ({
  label,
  isRequired,
  className,
  error = "",
  value,
  handleOnChange,
}) => {
  let checked = value == "1";
  let hasError = error.length > 0;
  let classes = ["form-group", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <Checkbox
        label={label}
        checked={checked}
        onChange={() => handleOnChange(checked ? "0" : "1")}
        isRequired={isRequired}
        error={error}
      />
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

export const DateField = ({
  name,
  label,
  isRequired = false,
  handleOnChange,
  value,
  error = "",
  className,
  start_year,
  end_year,
}) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label string required">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <div>
        <DatePicker
          onChange={handleOnChange}
          date={value ? moment(value) : null}
          initialMonth={moment()}
          id={name}
          isOutsideRange={(day) => isOutsideRange(start_year, end_year, day)}
          startYear={start_year}
          endYear={end_year}
        />
        {hasError ? <span className="help-block">{error}</span> : ""}
      </div>
    </div>
  );
};

const isOutsideRange = (start_year, end_year, day) => {
  if (!start_year && !end_year) {
    day.isAfter(moment());
  } else if (start_year && end_year) {
    day.isBetween(
      dateFromYear({ year: start_year }),
      dateFromYear({ year: end_year, start: false }),
      undefined,
      "[]"
    );
  }
};

const dateFromYear = ({ year, start = true }) => {
  if (!year) {
    return moment();
  }

  return `${year}-${start ? "01-01" : "12-31"}`;
};

export const TextField = ({
  name,
  type = "text",
  label,
  isRequired = false,
  readOnly = false,
  handleOnChange,
  value,
  error = "",
  className,
}) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label string required">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <input
        value={value}
        onChange={(e) => handleOnChange(e.target.value)}
        className={["form-control string", isRequired ? "required" : ""]}
        type={type}
        name={name}
        readOnly={readOnly}
      />
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

export const TextArea = ({
                            name,
                            label,
                            rows=5,
                            cols=80,
                            isRequired = false,
                            handleOnChange,
                            value,
                            error = "",
                            className,
                          }) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label string required">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <textarea
        cols={cols}
        rows={rows}
        onChange={(e) => handleOnChange(e.target.value)}
        className={["form-control string", isRequired ? "required" : ""]}
        name={name}
      >
        {value}
      </textarea>
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

const PolicyLabel = ({ label, policy, ...policyOptions }) => (
  <Label
    text={label}
    description={<PolicyModal {...policyOptions}>{policy}</PolicyModal>}
  />
);

const AcceptBoolean = ({ name, label, value, handleOnChange, error = "" }) => {
  let classes = [FieldStyles.header];
  if (error.length > 0) {
    classes.push(FieldStyles.fieldHasError);
  }

  return (
    <Field
      key={name}
      className={classes.join(" ")}
      label={<Label text={label} />}
      input={
        <Toggle
          colored={true}
          value={value}
          onChange={(newVal) => handleOnChange(newVal)}
        />
      }
    />
  );
};

const AcceptField = ({
  name,
  label,
  value,
  policy = "",
  policyTitle,
  policyLink,
  handleOnChange,
  error = "",
  optionLeft,
  optionRight,
  labelLeft,
  labelRight,
}) => {
  let classes = [FieldStyles.header];
  if (error.length > 0) {
    classes.push(FieldStyles.fieldHasError);
  }
  let setValue;
  if (typeof value === "string" && value.length > 0) {
    setValue = value;
  } else if (typeof value === "string") {
    setValue = optionLeft;
  } else {
    setValue = (!!value).toString();
  }

  return (
    <Field
      key={name}
      className={classes.join(" ")}
      label={
        policy && policy.length > 0 ? (
          <PolicyLabel
            label={label}
            policy={policy}
            policyTitle={policyTitle}
            policyLink={policyLink}
          />
        ) : (
          <Label text={label} />
        )
      }
      input={
        <Toggle
          type={Toggle.Types.CUSTOM}
          optionLeft={optionLeft}
          optionRight={optionRight}
          labelLeft={labelLeft}
          labelRight={labelRight}
          colored={true}
          value={setValue}
          onChange={(newVal) => handleOnChange(newVal)}
        />
      }
    />
  );
};

class PolicyModal extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { policyLink, policyTitle } = this.props;
    return (
      <div>
        <a
          href="#!"
          onClick={this.setState.bind(this, { showModal: true }, () => {})}
        >
          {policyLink.length > 0 ? policyLink : "View Policy"}
        </a>
        {this.state.showModal ? (
          <Modal
            {...this.props}
            showCancel={false}
            title={policyTitle}
            textModal={true}
            onConfirm={this.setState.bind(this, { showModal: false }, () => {})}
            onCancel={this.setState.bind(this, { showModal: false }, () => {})}
          >
            <div
              className={styles.modalContent}
              dangerouslySetInnerHTML={{ __html: this.props.children }}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}
