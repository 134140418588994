import React from "react";
import styles from "../MembershipRenewal.module.scss";

const WaitlistTitle = (waitlistActive) =>
  waitlistActive ? "Join our waitlist" : "Register now with ClubZap";

const WaitlistNote = (waitlistActive) =>
  waitlistActive
    ? "Sign up to join our membership waitlist"
    : "You can view and sign up to our membership packages";

const NewMembership = ({ waitlistActive, renewalPageNote }) => (
  <div className={styles.formContainer}>
    <h2>OR</h2>
    <div className={styles.packageList}>
      <h2>{WaitlistTitle(waitlistActive)}</h2>
      <p>
        {WaitlistNote(waitlistActive)} <a href="/membership_products">here</a>.
      </p>
    </div>
    {renewalPageNote && (
      <div
        className="renewal-page-note"
        dangerouslySetInnerHTML={{ __html: renewalPageNote }}
      />
    )}
  </div>
);

export default NewMembership;
