import React from "react";

import { unselectable } from "../../styles/base.scss";
import styles from "./Button.module.scss";

const noop = () => {};

interface ClassesType {
  disabled: boolean;
  color?: string;
  primary: boolean;
  progress: boolean;
}

const disabledClass = (disabled: boolean) => {
  if (disabled) {
    return styles.disabled;
  }
};
const disabledWhiteClass = (disabled: boolean, color?: string) => {
  if (disabled && color && color === "white") {
    return styles.white;
  }
};

const primaryClass = (disabled: boolean, primary: boolean) => {
  if (!disabled && primary) {
    return styles.primary;
  }
};

const colorClass = (disabled: boolean, color?: string) => {
  if (!disabled && color) {
    return styles[color];
  }
};

const progressClass = (disabled: boolean, progress: boolean) => {
  if (!disabled && progress) {
    return styles.progress;
  }
};

const condenseClasses = (
  classes: ({ [key: string]: {} } | string | undefined)[]
) =>
  classes.reduce((accum, element) => {
    if (element !== undefined) {
      accum.push(element);
    }

    return accum;
  }, []);

const computeClasses = ({
  disabled,
  color,
  primary,
  progress,
}: ClassesType): ({ [key: string]: {} } | string)[] =>
  condenseClasses([
    styles.button,
    unselectable,
    disabledClass(disabled),
    disabledWhiteClass(disabled, color),
    primaryClass(disabled, primary),
    colorClass(disabled, color),
    progressClass(disabled, progress),
  ]);

const computeStyleOverride = ({
  width,
  additionalStyles,
}: {
  width?: string;
  additionalStyles: React.CSSProperties | undefined;
}): React.CSSProperties | undefined => {
  if (width) {
    return {
      width: width,
      minWidth: width,
      ...additionalStyles,
    };
  }

  return {};
};

let Button = ({
  primary = false,
  disabled = false,
  color,
  onClick,
  value,
  width,
  progress = false,
  additionalStyles,
}: {
  primary?: boolean;
  disabled?: boolean;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  value: any;
  width?: string;
  progress?: boolean;
  additionalStyles?: {};
}) => {
  const hasOnClick = onClick && !disabled;
  const classes = computeClasses({
    disabled,
    color,
    primary,
    progress,
  });

  let clickHandler = hasOnClick ? onClick : noop;
  const styleOverride = computeStyleOverride({ width, additionalStyles });

  return (
    <a
      href="#!"
      role="button"
      style={styleOverride}
      className={classes.join(" ")}
      onClick={(e) => {
        e.preventDefault();
        clickHandler(e);
      }}
      onFocus={(e) => {
        if (disabled) e.target.blur();
      }}
    >
      <span>{value}</span>
    </a>
  );
};

export default Button;
