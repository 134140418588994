import React from "react";
import PropTypes from "prop-types";

import Fieldset from "lib/components/Fieldset/Fieldset";

import AdditionalInformation from "./AdditionalInformation";
import { DynamicField } from "./FormInputs";

const AddressForm = ({
  membership,
  membershipProduct,
  customFields,
  handleValueChange,
  handleCustomDataChange,
  countries,
  counties,
  errors,
}) => {
  return (
    <div className="member_fields">
      {membershipProduct.constraints.require_club_policy == "1" && (
        <Fieldset
          legend={`${
            membershipProduct.club_id != 2323 ? "Membership" : "Camp"
          } Policy Acceptance`}
          description={`Required validations and policy acceptance for ${membershipProduct.name}.`}
        >
          <DynamicField
            type="accept"
            name="club_policy_acceptance"
            label="Accept Club Policy?"
            value={membership["club_policy_acceptance"] || "0"}
            policy={membershipProduct.club_policy_text_s}
            policyTitle="Club Policy"
            policyLink="View Policy"
            isRequired={true}
            optionLeft="0"
            optionRight="1"
            labelLeft="No"
            labelRight="Yes"
            error={
              errors["club_policy_acceptance"]
                ? errors["club_policy_acceptance"][0]
                : ""
            }
            handleOnChange={(value) =>
              handleValueChange("club_policy_acceptance", value)
            }
          />
        </Fieldset>
      )}

      <Fieldset legend="Address Details">
        <DynamicField
          name="address_1"
          label="Address Line 1"
          value={membership["address_1"] || ""}
          error={errors["address_1"] ? errors["address_1"][0] : ""}
          isRequired={true}
          handleOnChange={(val) => handleValueChange("address_1", val)}
        />

        <DynamicField
          name="address_2"
          label="Address Line 2"
          value={membership["address_2"] || ""}
          error={errors["address_2"] ? errors["address_2"][0] : ""}
          isRequired={false}
          handleOnChange={(val) => handleValueChange("address_2", val)}
        />

        <DynamicField
          name="city"
          label="City / Town"
          value={membership["city"] || ""}
          error={errors["city"] ? errors["city"][0] : ""}
          isRequired={true}
          handleOnChange={(val) => handleValueChange("city", val)}
        />

        <DynamicField
          name="postcode"
          label="Postcode / Zip"
          value={membership["postcode"] || ""}
          error={errors["postcode"] ? errors["postcode"][0] : ""}
          isRequired={true}
          handleOnChange={(val) => handleValueChange("postcode", val)}
        />
        <DynamicField
          type="select"
          collection={countries}
          name="country_code"
          label="Country"
          value={membership["country_code"]}
          error={errors["country_code"] ? errors["country_code"][0] : ""}
          isRequired={true}
          handleOnChange={(val) => handleValueChange("country_code", val)}
        />

        {["IE", "US"].includes(membership["country_code"]) && (
          <DynamicField
            type="select"
            collection={counties[membership["country_code"]]}
            name="county_id"
            label="State / County"
            value={membership["county_id"]}
            error={errors["county_id"] ? errors["county_id"][0] : ""}
            isRequired={false}
            handleOnChange={(val) => handleValueChange("county_id", val)}
          />
        )}
      </Fieldset>

      {customFields && customFields.length > 0 && (
        <AdditionalInformation
          title={membershipProduct.name}
          customFields={customFields}
          customData={membership["custom_data"]}
          handleValueChange={handleCustomDataChange}
          errors={errors}
        />
      )}
    </div>
  );
}

AddressForm.propTypes = {
  membership: PropTypes.object.isRequired
};

export default AddressForm;
