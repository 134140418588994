import React from "react";
import PropTypes from "prop-types";

import styles from "./Membership.module.scss";

const MemberEntitySelector = ({
  types,
  handleEntitySelected,
  cancelSelection
}) => {
  let sortedTypes = types.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return (
    <div className={styles.packageSelector}>
      <p>Select a member type</p>
      {sortedTypes.map((type) => (
        <a key={type.id} onClick={() => handleEntitySelected(type.id)}>
          {type.name}
        </a>
      ))}
      <a
        onClick={(e) => {e.preventDefault(); cancelSelection()}}
        href="#!"
        className={styles.closeBtn}
      >
        Close
      </a>
    </div>
  );
};

MemberEntitySelector.propTypes = {
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleEntitySelected: PropTypes.func.isRequired,
  cancelSelection: PropTypes.func.isRequired
};

export default MemberEntitySelector;
