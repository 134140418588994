import React from "react";
import styles from "./Checkbox.module.scss";

const classes = (checked: boolean, indeterminate?: boolean): string[] => {
  let classArray = [styles.input];
  if (checked) {
    classArray.push(styles.checked);
  } else if (indeterminate) {
    classArray.push(styles.indeterminate);
  }
  return classArray;
};

const checkboxInner = (
  checked: boolean,
  indeterminate?: boolean
): JSX.Element | string => {
  if (checked) {
    return <i className="fa fa-check"></i>;
  } else if (indeterminate) {
    return <span className={styles.minus} />;
  }
  return "";
};

let Checkbox = ({
  label,
  checked,
  indeterminate,
  onChange,
  isRequired = false,
}: {
  label?: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (arg0: boolean) => void;
  isRequired: boolean;
}) => {
  return (
    <span
      className={classes(checked, indeterminate).join(" ")}
      onClick={() => onChange(!checked)}
    >
      {label ? (
        <label className={styles.label}>
          {isRequired && <abbr title="required">*</abbr>} {label}
        </label>
      ) : null}
      <span className={styles.checkbox}>
        {checkboxInner(checked, indeterminate)}
      </span>
    </span>
  );
};

export default Checkbox;
