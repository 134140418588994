import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import MembershipRenewal from "./MembershipRenewal";

const MembershipRenewalContainer = ({
  clubId,
  renewalPageNote,
  waitlistActive,
}) => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    status: "",
    message: "",
  });

  const findRenewal = async (values) => {
    setLoading(true);
    setResponseMessage({ status: "", message: "" });
    const request = {
      method: "post",
      url: `/membership_signup`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ club_id: clubId, email: values.email }),
    };

    try {
      const { data } = await axios(request);
      setLoading(false);
      setResponseMessage({ ...data });
    } catch (error) {
      setLoading(false);
      if (400 < error.response.status < 500) {
        setResponseMessage({ ...error.response.data });
      } else {
        setResponseMessage({
          message:
            "An error occurred while finding your membership. Please try again.",
        });
      }
    }
  };

  return (
    <MembershipRenewal
      responseMessage={responseMessage}
      loading={loading}
      onSubmit={findRenewal}
      renewalPageNote={renewalPageNote}
      waitlistActive={waitlistActive}
    />
  );
};

MembershipRenewalContainer.propTypes = {
  clubId: PropTypes.number.isRequired,
};

export default MembershipRenewalContainer;
