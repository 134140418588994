import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";

import LoaderDots from "lib/components/LoaderDots/LoaderDots";
import FormResponse from "./Components/FormResponse";
import NewMembership from "./Components/NewMembership";
import styles from "./MembershipRenewal.module.scss";

const MembershipRenewal = ({
  responseMessage,
  loading,
  onSubmit,
  renewalPageNote,
  waitlistActive,
}) => {
  return (
    <div className={styles.renewalOuterContainer}>
      <div className={styles.renewalContainer}>
        <p>Enter your email address to renew your existing membership</p>
        <div className={styles.formContainer}>
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={async (values) => {
              await onSubmit(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="email">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div
                      className={`${styles.inputContainer} ${
                        meta.touched && meta.error ? styles.inputError : ""
                      }`}
                    >
                      <input
                        type="text"
                        placeholder="Email"
                        {...field}
                        disabled={responseMessage.status === "success"}
                      />
                      {meta.touched && meta.error && (
                        <div className={styles.error}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <button
                  type="submit"
                  disabled={
                    isSubmitting || responseMessage.status === "success"
                  }
                  className={styles.renewalButton}
                >
                  Start Renewal
                </button>
              </Form>
            )}
          </Formik>
        </div>
        {loading && (
          <div className={styles.responseContainer}>
            <h3>Loading</h3>
            <LoaderDots />
          </div>
        )}
        {responseMessage.status.length > 0 && (
          <FormResponse responseMessage={responseMessage} />
        )}
        <NewMembership
          waitlistActive={waitlistActive}
          renewalPageNote={renewalPageNote}
        />
      </div>
    </div>
  );
};

MembershipRenewal.propTypes = {
  responseMessage: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MembershipRenewal;
