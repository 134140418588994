import React, { useEffect, useState } from "react";
import axios from "axios";

import FieldStyles from "../../../lib/components/Field/Field.module.scss";
import { BaseSelect } from "../../../lib/components/SelectField";
import { LottoMetaTypes } from "./LottoEntriesForm";

type LottoEntryFieldTypes = {
  lottoMeta: LottoMetaTypes;
  label: string;
  numbers: (string | number)[];
  handleOnChange: (numbers: (string | number)[]) => void;
  className?: string;
  error: string;
};

const LottoEntryField = ({
  lottoMeta,
  label,
  numbers,
  handleOnChange,
  error = "",
}: LottoEntryFieldTypes) => {
  const [selectedNumbers, setSelectedNumbers] = useState(numbers);
  const [loading, setLoading] = useState(false);

  const onNumbersSelected = (index: number, number: number) => {
    setSelectedNumbers((existingNumbers) => {
      return [
        ...existingNumbers.slice(0, index),
        number,
        ...existingNumbers.slice(index + 1),
      ];
    });
  };

  useEffect(() => {
    handleOnChange(selectedNumbers);
  }, [selectedNumbers]);

  let hasError = error.length > 0;
  let classes = ["form-group string", "required", FieldStyles.reactFormGroup];
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  const doQuickPick = async () => {
    setLoading(true);
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/clubs/${lottoMeta.club_id}/draws/quick_pick`,
        headers: {
          Accept: "application/json;version=3",
          "X-ClubZap-App-Id": "ClubZapWebsite",
          "Content-Type": "application/json",
        },
      });
      setSelectedNumbers(data);
    } catch (error) {
      window.alert("Failed to generate a quick pick - please try again!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.join(" ")}>
      <label className="control-label select">
        <abbr title="required">*</abbr> {label}
      </label>
      <div>
        {Array.from(Array(lottoMeta.quantity_drawn)).map((_, innerIndex) => (
          <BaseSelect
            key={`${label} - ${innerIndex}`}
            value={selectedNumbers[innerIndex]}
            collection={Array.from(
              { length: lottoMeta.highest_number },
              (_, i) => (i + 1).toString()
            )}
            handleOnChange={(value: string) =>
              onNumbersSelected(innerIndex, parseInt(value))
            }
            style={{
              width: "70px",
              padding: "0px 10px",
              display: "inline",
              margin: "0 20px 0 0",
            }}
            placeholder={""}
          />
        ))}
        <div
          style={{
            display: "inline-flex",
            height: "50px",
            alignItems: "center",
          }}
        >
          {loading ? (
            <span>Generating Picks</span>
          ) : (
            <a href="#!" onClick={doQuickPick}>
              Quick Pick
            </a>
          )}
        </div>
        {hasError ? <span className="help-block">{error}</span> : ""}
      </div>
    </div>
  );
};

export default LottoEntryField;
