import React from "react";
import PropTypes from "prop-types";

import FieldStyles from "../Field/Field.module.scss";
import { __PRIVATE__ } from "styled-components";

const SelectField = ({
  label,
  collection,
  orderAlphabetically = false,
  value,
  isRequired,
  handleOnChange,
  className,
  error = "",
  ...args
}) => {
  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label select">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <BaseSelect
        value={value}
        collection={collection}
        handleOnChange={handleOnChange}
        orderAlphabetically={orderAlphabetically}
        {...args}
      />
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  collection: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export const BaseSelect = ({
  collection,
  orderAlphabetically,
  value,
  handleOnChange,
  hideDefault = false,
  readOnly = false,
  placeholder = "--Please Select--",
  style = {},
  ...args
}) => {
  let optionValue;
  if (!value) {
    optionValue = "";
  } else {
    optionValue = value;
  }
  const removeArg =
    (prop) =>
    ({ [prop]: _, ...rest }) =>
      rest;
  const removeRequired = removeArg("is_required?");
  const safeArgs = ({
    collection,
    orderAlphabetically,
    optionLeft,
    labelLeft,
    optionRight,
    labelRight,
    policy,
    policyLink,
    policyTitle,
    start_year,
    end_year,
    memberIndex,
    ...rest
  }) => removeRequired({ ...rest });
  return (
    <select
      className="form-control select required"
      onChange={(e) => handleOnChange(e.target.value)}
      value={optionValue}
      style={style}
      {...safeArgs({ ...args })}
    >
      {!hideDefault && <option value={""}>{placeholder}</option>}
      {orderedCollection({ collection, orderAlphabetically }).map(
        (option, index) =>
          Array.isArray(option) ? (
            <option key={index.toString()} value={option[0]}>
              {option[1]}
            </option>
          ) : (
            <option key={index.toString()} value={option}>
              {option}
            </option>
          )
      )}
    </select>
  );
};

const orderedCollection = ({ collection, orderAlphabetically }) =>
  orderAlphabetically ? collection.sort() : collection;

export default SelectField;
