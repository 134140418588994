import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import LoaderDots from "lib/components/LoaderDots/LoaderDots";

import FieldStyles from "../Field/Field.module.scss";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FileField = ({
  label,
  value,
  isRequired,
  className,
  error = "",
  onFileDrop,
  loading,
}) => {
  const [changingFile, setChangingFile] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    onFileDrop(acceptedFiles);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg,image/jpg,image/png",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  let hasError = error.length > 0;
  let classes = ["form-group string", FieldStyles.reactFormGroup];
  if (className) {
    classes.push(className);
  }
  if (isRequired) {
    classes.push("required");
  }
  if (hasError) {
    classes.push(FieldStyles.hasError);
  }

  return (
    <div className={classes.join(" ")}>
      <label className="control-label select">
        {isRequired && <abbr title="required">*</abbr>} {label}
      </label>
      <div>
        {loading ? (
          <LoaderDots />
        ) : (
          <div>
            {value && (
              <div>
                <img alt="Preview" src={value} style={{ maxHeight: "100px" }} />
                <div style={{ display: "flex", padding: "5px" }}>
                  <a
                    onClick={() => setChangingFile(!changingFile)}
                    href="javascript:void(0)"
                  >
                    {changingFile ? "Cancel" : "Change File"}
                  </a>
                </div>
              </div>
            )}
            {((typeof(value) === 'string' && value.length === 0) || changingFile) && (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here ...</p>
                ) : (
                  <div>
                    <p>Drag 'n' drop your file</p>
                    <p>
                      <strong>Or Click Here</strong>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {hasError ? <span className="help-block">{error}</span> : ""}
    </div>
  );
};

FileField.propTypes = {
  label: PropTypes.string.isRequired,
  onFileDrop: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const FileFieldContainer = ({handleOnChange, ...args}) => {
  const [uploading, setUploading] = useState(false);
  const onFileDrop = async (acceptedFiles) => {
    setUploading(true);
    const file = acceptedFiles[0];
    const fileData = {
      upload: {
        filename: file.name.replace(/[^a-z0-9.]/gi, "_").toLowerCase(),
        bucket: 'private',
      },
    };

    try {
      const { data } = await axios({
        method: "post",
        url: "/api/uploads",
        data: JSON.stringify(fileData),
        headers: {
          Accept: "application/json;version=3",
          "X-ClubZap-App-Id": "ClubZapWebsite",
          "Content-Type": "application/json",
        }
      });

      const options = {
        headers: {
          "Content-Type": file.type,
        }
      };

      await axios.put(data.upload.url, file, options);

      setUploading(false);
      handleOnChange(data.upload.preview_url);
    } catch (error) {
      setUploading(false);
    }
  };

  return <FileField onFileDrop={onFileDrop} loading={uploading} {...args} />;
};

FileFieldContainer.propTypes = {
  label: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default FileFieldContainer;
