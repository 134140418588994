import React, { Children, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";

import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.scss";

const DatePicker = ({
  date,
  initialMonth,
  onChange,
  placeholder,
  small,
  id,
  isOutsideRange,
  showDefaultInputIcon = true,
  startYear = moment().subtract(99, "years").year(),
  endYear = moment().year(),
}) => {
  const [focused, setFocused] = useState(false);
  const initialMonthComp = () => {
    return date || initialMonth || moment().subtract(5, "years");
  };

  return (
    <SingleDatePicker
      placeholder={placeholder || "Select date"}
      showClearDate={true}
      showDefaultInputIcon={showDefaultInputIcon}
      isOutsideRange={isOutsideRange}
      numberOfMonths={1}
      initialVisibleMonth={() => initialMonthComp()}
      date={date}
      hideKeyboardShortcutsPanel={true}
      onDateChange={(newDate) => onChange(newDate)}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
        MonthElement({
          month,
          onMonthSelect,
          onYearSelect,
          startYear,
          endYear,
        })
      }
      displayFormat="DD/MM/YYYY"
      small={small}
      id={id || "date"}
    />
  );
};

DatePicker.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  id: PropTypes.string,
};

const MonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
  startYear,
  endYear,
}) => (
  <div
    data-testid="month-element"
    style={{ display: "flex", justifyContent: "center" }}
  >
    <div>
      <DateMetaSelect
        testId={`month-select-${month.month()}`}
        value={month.month()}
        onChange={(e) => onMonthSelect(month, e.target.value)}
      >
        {moment.months().map((label, value) => (
          <option value={value} key={label}>
            {label}
          </option>
        ))}
      </DateMetaSelect>
    </div>
    <div>
      <DateMetaSelect
        testId={`year-select-${month.month()}-${month.year()}`}
        value={month.year()}
        onChange={(e) => onYearSelect(month, e.target.value)}
      >
        {Array.from(
          { length: endYear - startYear + 1 },
          (_, i) => startYear + i
        ).map((label, value) => (
          <option
            data-testid={`year-option-${month.month()}`}
            value={label}
            key={label}
          >
            {label}
          </option>
        ))}
      </DateMetaSelect>
    </div>
  </div>
);

const DateMetaSelect = ({ testId, value, onChange, children }) => (
  <select
    data-testid={testId}
    style={{
      height: "30px",
      background: "#efefef",
      marginLeft: "3px",
      border: "none",
    }}
    value={value}
    onChange={onChange}
  >
    {children}
  </select>
);

MonthElement.propTypes = {
  month: PropTypes.object.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
};

export default DatePicker;
