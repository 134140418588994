import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

export default class HomepageSlider extends React.Component{

  constructor(props) {
    super(props);
    this.state = {slideCount: this.props.images.length, currentSlide: 1};
    this.slideChange = this.slideChange.bind(this);
  }

  slideChange(currentSlide) {
    this.setState({currentSlide: currentSlide + 1});
  }

  imageClicked(image) {
    if (image.link) {
      window.open(image.link, "_blank");
    }
  }

  render () {
    var settings = {
      dots: false,
      infinite: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: this.slideChange,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 2000,
      pauseOnHover: true,
      adaptiveHeight: false,
    };
    return (
      <div className='slider'>
        <div className='slide-count'><p>{this.state.currentSlide}/{this.state.slideCount}</p></div>
        <Slider {...settings}>
          {this.props.images.map((image, index) => {
            return (
              <div key={index} style={{cursor: image.link ? 'pointer' : 'default'}} onClick={() => this.imageClicked(image)}>
                <div className="caption">
                  <h1>{image.title}</h1>
                  <p>{image.subtitle}</p>
                </div>
                <img src={image.website_url} width="1400" height="750" />
              </div>
            )
          })}
        </Slider>
      </div>
    );
  }
}

HomepageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired
};
