export const renderCurrency = ({ price, currency, locale }) => {
  const currencyArgs = {
    style: "currency",
    currency: currency
  };

  if (price < 0) {
    return "(" + (price * -1.0).toLocaleString(`en-${locale || "IE"}`, currencyArgs) + ")";
  } else {
    return price.toLocaleString(`en-${locale || "IE"}`, currencyArgs);
  }
};
