import React from "react";

import Fieldset from "../../../lib/components/Fieldset/Fieldset";
import LottoEntryField from "./LottoEntryField";

export type LottoMetaTypes = {
  draw_name: string;
  club_id: number;
  highest_number: number;
  quantity_drawn: number;
};

type LottoEntriesFormTypes = {
  drawProduct: {
    entry_count: number;
    description: string;
    membership_bundles_use_product_description: boolean;
  };
  lottoMeta: LottoMetaTypes;
  drawEntries: { numbers: (string | number)[]; id: number }[];
  handleEntriesChanged: (
    index: number,
    entry: { id: number; numbers: (string | number)[] }
  ) => void;
  errors: { numbers: string[] }[];
};

type ErrorHashType = {
  [key: number]: { numbers: (string | number)[] };
};

const scopedErrors = (
  errorHash: ErrorHashType,
  index: number,
  object: { id: number; numbers: (string | number)[] }
) => {
  const key = object?.id || index;
  let scopedErrorHash = "";
  if (errorHash && errorHash[key] && errorHash[key]["numbers"]) {
    scopedErrorHash = errorHash[key]["numbers"].join(", ");
  }
  return scopedErrorHash;
};

const LottoEntriesForm = ({
  drawProduct,
  lottoMeta,
  drawEntries,
  handleEntriesChanged,
  errors,
}: LottoEntriesFormTypes) => {
  return (
    <div className="member_fields">
      <Fieldset
        legend={lottoMeta.draw_name}
        description={
          drawProduct.membership_bundles_use_product_description
            ? drawProduct.description
            : "Please select your numbers. Entries will be associated with the name and email address of the person paying."
        }
      >
        {Array.from(Array(drawProduct.entry_count)).map((entry, index) => (
          <div key={`entry-${index}`}>
            <LottoEntryField
              lottoMeta={lottoMeta}
              numbers={drawEntries[index] ? drawEntries[index]["numbers"] : []}
              label={
                drawProduct.entry_count > 1
                  ? `Entry ${index + 1} - Select numbers from 1 to ${
                      lottoMeta.highest_number
                    }`
                  : `Select numbers from 1 to ${lottoMeta.highest_number}`
              }
              handleOnChange={(numbers) => {
                handleEntriesChanged(index, {
                  ...drawEntries[index],
                  numbers: numbers,
                });
              }}
              error={scopedErrors(errors, index, drawEntries[index])}
            />
          </div>
        ))}
      </Fieldset>
    </div>
  );
};

export default LottoEntriesForm;
