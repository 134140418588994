import React from "react";
import PropTypes from "prop-types";

import Fieldset from "lib/components/Fieldset/Fieldset";
import { DynamicField } from "./FormInputs";
import Receipt from "./Receipt";

import styles from "./Membership.module.scss";

const ParentOrReceipt = ({
  requireParent,
  membership,
  membershipProduct,
  handlePayeeChange,
  errors,
  handleAddingParent,
  handleParentValueChange,
  handleParentCustomValueChange,
  scrollToAddMember,
}) => {
  const payeeIndex = membership.members.findIndex(mem => mem.is_payee);
  const payeeOptions = membership.members
    .map((mem, index) => [index, mem])
    .filter(
      mem =>
        !["Child", "Youth"].includes(mem[1].entity?.membership_type)
    )
    .map(mem => [
      mem[0],
      `${mem[1].forename} ${mem[1].surname} (${mem[1].email})`
    ]);

  let parentErrors = {};
  if (membership.addingParent && errors['payee_id']) {
    parentErrors = {
      parent_forename: ["can't be blank"],
      parent_surname: ["can't be blank"],
      parent_email: ["can't be blank"],
      parent_mobile: ["can't be blank"],
    };
  }

  const parentPresent =
    membership.parent_forename ||
    membership.parent_surname ||
    membership.parent_email ||
    membership.parent_mobile;
  const entityRequirements =
    membershipProduct.constraints["entity_requirements"];
  const entityIds = membership.members.map(mem => mem.member_entity_id);
  const hasParentEntityRequirement =
    entityRequirements &&
    entityRequirements.filter(
      (mem) =>
        !mem["excluded_entities"] ||
        (mem["excluded_entities"] &&
          entityIds.filter((n) => !mem["excluded_entities"].includes(n))
            .length > 0)
    ).length > 0 &&
    entityRequirements.filter((mem) =>
      mem["type"].split("|").some((value) => ["Full", "Adult"].includes(value))
    ).length > 0;
  const showReceipt =
    payeeIndex > -1 ||
    payeeOptions.length > 0 ||
    (membership.members.length > 0 && hasParentEntityRequirement);

  return (
    <div className="member_fields">
      {showReceipt && (
        <Receipt
          membership={membership}
          handlePayeeChange={handlePayeeChange}
          errors={errors["payee_id"] || []}
        />
      )}
      {((!showReceipt && (parentPresent || requireParent)) ||
        membership.addingParent) && (
        <Parent
          showSwitchLink={!requireParent}
          membershipProduct={membershipProduct}
          membership={membership}
          errors={{...parentErrors, ...errors} || []}
          handleValueChange={handleParentValueChange}
          handleCustomValueChange={handleParentCustomValueChange}
          handleRemoveParent={() => handleAddingParent(true)}
        />
      )}
      {!showReceipt &&
        !requireParent &&
        !parentPresent &&
        !membership.addingParent &&
        membership.members.length > 0 && (
          <Fieldset
            legend="Parental Details"
            description="It is mandatory to have a parent/guardian associated with a child membership. Please provide a contact."
          >
            <div className={styles.parentOrReceipt}>
              <h3>EITHER</h3>
              <p>(1) Add an adult member to the membership above.</p>
              <a onClick={scrollToAddMember} className={"btn btn-primary"}>
                Add Parent Member
              </a>
              <h3>OR</h3>
              <p>(2) Add a non-member parent.</p>
              <a
                onClick={() => handleAddingParent(false)}
                className={"btn btn-primary"}
              >
                Add Parent (Non-Member)
              </a>
            </div>
            {errors["payee_id"] && errors["payee_id"].length > 0 && (
              <p className={styles.baseError}>
                adult member or parent details must be present.
              </p>
            )}
          </Fieldset>
        )}
    </div>
  );
};

ParentOrReceipt.propTypes = {
  membership: PropTypes.object.isRequired,
  requireParent: PropTypes.bool
};

const Parent = ({
  showSwitchLink,
  membershipProduct,
  membership,
  errors,
  handleValueChange,
  handleCustomValueChange,
  handleRemoveParent,
}) => {
  return (
    <Fieldset
      legend="Parent / Guardian Details"
      description={`${
        membershipProduct.clubId != 2323
          ? "If not adding any adult members, p"
          : "P"
      }lease provide parent details.`}
    >
      {showSwitchLink && (
        <p>
          Or click <a onClick={handleRemoveParent}>here</a> to add an adult
          member to your membership purchase instead.
        </p>
      )}
      <DynamicField
        name="parent_forename"
        label="Parent/guardian first name"
        value={membership["parent_forename"]}
        error={errors["parent_forename"] ? errors["parent_forename"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_forename", val)}
      />

      <DynamicField
        name="parent_surname"
        label="Parent/guardian last name"
        value={membership["parent_surname"]}
        error={errors["parent_surname"] ? errors["parent_surname"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_surname", val)}
      />

      <DynamicField
        name="parent_email"
        label="Parent/guardian email"
        value={membership["parent_email"]}
        error={errors["parent_email"] ? errors["parent_email"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_email", val)}
      />

      <DynamicField
        name="parent_mobile"
        label="Parent/guardian mobile number"
        value={membership["parent_mobile"]}
        error={errors["parent_mobile"] ? errors["parent_mobile"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_mobile", val)}
      />
      {(membershipProduct.constraints["require_parent_2"] === "1" ||
        membershipProduct.constraints["mandatory_parent_2"] === "1") && (
          <div>
            <DynamicField
              name="parent_2_forename"
              label="2nd Parent/guardian first name"
              value={membership["custom_data"]["parent_2_forename"] || ''}
              error={
                errors["parent_2_forename"]
                  ? errors["parent_2_forename"][0]
                  : ""
              }
              isRequired={
                membershipProduct.constraints["mandatory_parent_2"] === "1"
              }
              handleOnChange={(val) =>
                handleCustomValueChange("parent_2_forename", val)
              }
            />

            <DynamicField
              name="parent_2_surname"
              label="2nd Parent/guardian last name"
              value={membership["custom_data"]["parent_2_surname"] || ''}
              error={
                errors["parent_2_surname"] ? errors["parent_2_surname"][0] : ""
              }
              isRequired={
                membershipProduct.constraints["mandatory_parent_2"] === "1"
              }
              handleOnChange={(val) =>
                handleCustomValueChange("parent_2_surname", val)
              }
            />

            <DynamicField
              name="parent_2_email"
              label="2nd Parent/guardian email"
              value={membership["custom_data"]["parent_2_email"] || ''}
              error={
                errors["parent_2_email"] ? errors["parent_2_email"][0] : ""
              }
              isRequired={
                membershipProduct.constraints["mandatory_parent_2"] === "1"
              }
              handleOnChange={(val) => handleCustomValueChange("parent_2_email", val)}
            />

            <DynamicField
              name="parent_2_mobile"
              label="2nd Parent/guardian mobile number"
              value={membership["custom_data"]["parent_2_mobile"] || ''}
              error={
                errors["parent_2_mobile"] ? errors["parent_2_mobile"][0] : ""
              }
              isRequired={
                membershipProduct.constraints["mandatory_parent_2"] === "1"
              }
              handleOnChange={(val) =>
                handleCustomValueChange("parent_2_mobile", val)
              }
            />
          </div>
        )}
    </Fieldset>
  );
};

export default ParentOrReceipt;
