import React from "react";
import PropTypes from "prop-types";

import Fieldset from "lib/components/Fieldset/Fieldset";
import { DynamicField } from "./FormInputs";

const sortedFields = fields =>
  Object.keys(fields).sort(
    (fieldA, fieldB) => fields[fieldA]["order"] - fields[fieldB]["order"]
  );

const AdditionalInformation = ({
  index,
  customFields,
  customData,
  handleValueChange,
  errors,
}) => (
  <Fieldset
    legend={"Additional Information"}
    description={"Additional information required for registration"}
  >
    {(customFields && customFields.length > 0) &&
      customFields.map((field) => (
        <DynamicField
          key={field["id"]}
          name={field["name"]}
          type={field["field_type"]}
          label={field["label"]}
          {...field["input_options"]}
          value={customData[field["name"]] || ""}
          isRequired={field["required"]}
          memberIndex={index}
          handleOnChange={(value) =>
            Number.isInteger(index)
              ? handleValueChange(index, field["name"], value)
              : handleValueChange(field["name"], value)
          }
          error={errors ? errors[field["name"]] : ""}
        />
      ))}
  </Fieldset>
);

AdditionalInformation.propTypes = {
  index: PropTypes.number,
  customData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default AdditionalInformation;
