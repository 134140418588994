import React, { useState } from "react";

import FileField from "../../lib/components/FileField";

const PurchaseFileUpload = ({
  field,
  initialValue,
}: {
  field: { label: string; required: boolean; name: string };
  initialValue: string;
}) => {
  const [fileUrl, setFileUrl] = useState(initialValue);

  return (
    <div>
      <FileField
        label={field.label}
        value={fileUrl}
        isRequired={field.required}
        handleOnChange={(url: string) => setFileUrl(url)}
      />
      <input
        data-testid="file-url-input"
        type="hidden"
        name={`purchase[custom_data][${field.name}]`}
        value={fileUrl}
      />
    </div>
  );
};

export default PurchaseFileUpload;
