import ReactOnRails from "react-on-rails";

import HomepageSlider from "components/HomepageSlider/HomepageSlider";
import DobPicker from "components/DatePickers/DobPicker";
import MembershipCheckout from "Modules/MembershipCheckout";
import MembershipRenewal from "Modules/MembershipRenewal";
import PurchaseFileUpload from "Modules/PurchaseFileUpload";

ReactOnRails.register({
  HomepageSlider,
  DobPicker,
  MembershipCheckout,
  MembershipRenewal,
  PurchaseFileUpload,
});
